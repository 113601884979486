<template>
    <div class="news">
        <div class="img-box pr">
            <el-image class="wi100 hi100" :src="bg" fit="cover"></el-image>
            <div class="ban-dec uf uf-ver uf-pc">
                <div class="content-box uf uf-ac uf-ver pt-4 mt-3">
                    <div class="tit fwb">新闻公告</div>
                    <div class="news-tabs mt-4 uf uf-ac">
                        <div class="tab-list mr-2 cp" :class="{on: index === curNewsTabIndex}"
                             v-for="(item, index) in newsTab" :key="index" @click="getDataList(index)">
                            <span>{{ item }}</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-box pt-3 pb-2">
            <div class="n-list cp uf uf-ac uf-pj" v-for="item in dataList" :key="item.id" @click="showDetail(item)">
                <el-image
                        :src="item.cover"
                        class="mr-3 img-box" style="width: 90px;height: 90px" fit="cover">
                    <div slot="error" class="wi100 hi100">
                        <img v-if="curNewsTabIndex === 0" src="../../assets/img/news-de.jpg" width="100%" alt="" />
                        <img v-else src="../../assets/img/notice-de.jpg" width="100%" alt="" />
                    </div>
                </el-image>
                <div class="fwb uf-f1 toe">{{ curNewsTabIndex === 0 ? item.newsName : item.noticeName }}</div>
                <div class="f14 op-07 pl-3">{{ item.updateTime}}</div>
            </div>
        </div>
<!--        <div v-html="rich"></div>-->
        <div class="pagination tac">
            <el-pagination
                background
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageIndex"
                :page-sizes="[10, 20, 50]"
                :page-size="pageSize"
                :total="totalCount"
                layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <detail-dialog v-if="detailVisible" ref="detail"></detail-dialog>
    </div>
</template>

<script>
import DetailDialog from '@/views/news/detail-dialog.vue'
export default {
    name: 'news',
    components: {
        DetailDialog
    },
    data() {
        return {
            detailVisible: false,
            bg: require('../../assets/img/news.jpg'),
            curNewsTabIndex: 0,
            newsTab: ['新闻资讯', '通知公告'],
            dataList: [],
            pageIndex: 1,
            pageSize: 10,
            totalCount: 0,
            totalPage: 0,
        }
    },
    created() {
        this.getDataList()
    },
    methods: {
        getDataList(index) {
            this.curNewsTabIndex = index || 0
            this.$http({
                url: this.$http.adornUrl(this.curNewsTabIndex === 0 ? this.$api.NEWS.LIST : this.$api.NOTICE.LIST),
                method: 'get',
                params: this.$http.adornParams({
                    // 'page': this.pageIndex,
                    // 'limit': this.pageSize,
                    // 'serviceName': this.dataForm.serviceName || null,
                    // 'itemCode': this.dataForm.itemCode || null ,
                    // 'orgCode': this.dataForm.orgCode || null,
                    // 'serviceMethod': this.dataForm.serviceMethod === 0 ? null : this.dataForm.serviceMethod,
                    // 'sortType': this.dataForm.sortType || null,
                    // 'priceLow': this.dataForm.priceLow || null,
                    // 'priceHigh': this.dataForm.priceHigh || null
                    'startPage': this.pageIndex - 1,
                    'pageSize': this.pageSize,
                    'newsStatus': 1,
                    'noticeStatus': 1
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    let list = data.page.list
                    list.forEach( v => {
                        v.cover = this.getFirstImg(this.curNewsTabIndex === 0 ? v.newsObj : v.noticeObj)
                    })
                    this.dataList = list
                }
                this.visible = true
            }).catch(() => {
                this.visible = true
            })
        },
        // 截取富文本第一张图片
        getFirstImg (str){
            var data = []
            str.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/,  (match, capture) => {
                data.push(capture)
            })
            return data[0]
        },
        // 选择产品
        showDetail (item) {
            this.detailVisible = true
            this.$nextTick(() => {
                this.$refs['detail'].init(item)
            })
            // this.
            // let itemCodeType = 'search'
            // this.keepAliveArr = ['search']
            // this.$router.push({name: 'service', query: {id: item.id,itemCodeType:itemCodeType}})
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val
            this.pageIndex = 1
            this.getDataList(true)
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.getDataList(false)
        }
    },
}
</script>
<!--transform: skewX(-30deg);-->
<style scoped lang="scss">
.news {
  margin-top: -50px;
}

.img-box {
  width: 100vw;
  height: 26vw;
}

.pagination {
  position: sticky;
  bottom: 0;
  background: #fff;
  padding: 10px;
}

.tit {
  font-size: 4vw;
  padding-top: 4vw;
  letter-spacing: 9px;
  color: white;
}

.content-box {
  width: 1325px;
  margin: 0 auto;
  padding-bottom: 40px;
}
.img-box {
  border: 1px solid #e1e1e1;
}
.n-list {
  padding: 15px 20px;
  //background: #eeeeee;
  border-bottom: 1px dashed #a473ff;
  //margin-bottom: 20px;
  //border-radius: 10px;
  transition: all .3s ease;

  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  }
}

.news-tabs {
  .tab-list {
    width: 120px;
    line-height: 40px;
    color: white;
    background: #001231;
    transform: skewX(-30deg);
    text-align: center;
    transition: all 0.3s ease;

    span {
      transform: skewX(30deg);
    }
  }

  .on {
    color: #000;
    background: #56fff9;
  }
}

.ban-dec {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .dec-box {
    text-align: center;
    color: #ffffff;
    padding: 30px;

    .dec-tit {
      display: inline-block;
      background: transparent;
      font-size: 5vw;
      letter-spacing: 5px;
      margin-bottom: 1vw;
      text-shadow: 0 0 30px rgba(0, 0, 0, .4);
    }

    .dec-dec {
      line-height: 35px;
      opacity: .7;
    }
  }
}
</style>
